// SETTINGS CONSTANTS
export const LOGO_TYPE = "LOGO_TYPE";
export const LOGO_BG = "LOGO_BG";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const THEME = "THEME";
export const DIRECTION = "DIRECTION";
export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
export const HEADER_POSITION = "HEADER_POSITION";
export const LAYOUT = "LAYOUT";
export const SIDEBAR_TYPE = "SIDEBAR_TYPE";

//LOGIN CONSTANTS
export const FETCH_USERNAME = "FETCH_USERNAME";
export const CLEAR_CREDENTIALS = "CLEAR_CREDENTIALS";

// OCCUPATIONAL INJURY CONSTANTS
export const FETCH_NATURE_OF_INJURY_VALUE = "FETCH_NATURE_OF_INJURY_VALUE";
export const FETCH_SOURCE_OF_INJURY_VALUE = "FETCH_SOURCE_OF_INJURY_VALUE";
export const FETCH_BODY_AFFECTED_VALUE = "FETCH_BODY_AFFECTED_VALUE";
export const FETCH_EXPOSURE_VALUE = "FETCH_EXPOSURE_VALUE";
export const FETCH_NATURE_OF_INJURY_ID = "FETCH_NATURE_OF_INJURY_ID";
export const FETCH_SOURCE_OF_INJURY_ID = "FETCH_SOURCE_OF_INJURY_ID";
export const FETCH_BODY_AFFECTED_ID = "FETCH_BODY_AFFECTED_ID";
export const FETCH_EXPOSURE_ID = "FETCH_EXPOSURE_ID";
export const FETCH_OCCUPATIONAL_NOTES = "FETCH_OCCUPATIONAL_NOTES";
export const FETCH_OCCUPATIONAL_LOCATION = "FETCH_OCCUPATIONAL_LOCATION";
export const CLEAR_STORE = "CLEAR_STORE";

//PATIENTLABEL GLOBAL CONSTANT
export const FETCH_PATIENT_LABEL_VALUE = "FETCH_PATIENT_LABEL_VALUE";

//Normal Login
export const NORMAL_LOGIN_INITIALIZE = "NORMAL_LOGIN_INITIALIZE";
export const NORMAL_LOGIN_SUCCESS = "NORMAL_LOGIN_SUCCESS";
export const NORMAL_LOGIN_FAILED = "NORMAL_LOGIN_FAILED";
export const GET_GLOBAL_SETTING_INITIALIZE = "GET_GLOBAL_SETTING_INITIALIZE";
export const GET_GLOBAL_SETTING_SUCCESS = "GET_GLOBAL_SETTING_SUCCESS";
export const GET_GLOBAL_SETTING_FAILED = "GET_GLOBAL_SETTING_FAILED";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const NORMAL_LOGOUT = "NORMAL_LOGOUT";

//GET Lookup
export const GET_LOOKUP_DATA_INITIALIZE = "GET_LOOKUP_DATA_INITIALIZE";
export const GET_LOOKUP_DATA_SUCCESS = "GET_LOOKUP_DATA_SUCCESS";
export const GET_LOOKUP_DATA_FAILED = "GET_LOOKUP_DATA_FAILED";

//GET serving
export const GET_SERVING_INITIALIZE = "GET_SERVING_INITIALIZE";
export const GET_SERVING_SUCCESS = "GET_SERVING_SUCCESS";
export const GET_SERVING_FAILED = "GET_SERVING_FAILED";

//Recipe Search
export const RECIPE_SEARCH_INITIALIZE = "RECIPE_SEARCH_INITIALIZE";
export const RECIPE_SEARCH_SUCCESS = "RECIPE_SEARCH_SUCCESS";
export const RECIPE_SEARCH_FAILED = "RECIPE_SEARCH_FAILED";

//GET Meal Filter Data
export const GET_MEAL_FILTER_DATA_INITIALIZE =
    "GET_MEAL_FILTER_DATA_INITIALIZE";
export const GET_MEAL_FILTER_DATA_SUCCESS = "GET_MEAL_FILTER_DATA_SUCCESS";
export const GET_MEAL_FILTER_DATA_FAILED = "GET_MEAL_FILTER_DATA_FAILED";

//GET meal plan
export const GET_MEAL_PLANS_INITIALIZE = "GET_MEAL_PLANS_INITIALIZE";
export const GET_MEAL_PLANS_SUCCESS = "GET_MEAL_PLANS_SUCCESS";
export const GET_MEAL_PLANS_FAILED = "GET_MEAL_PLANS_FAILED";

//ADD meal plan
export const CREATE_MEAL_PLANS_INITIALIZE = "CREATE_MEAL_PLANS_INITIALIZE";
export const CREATE_MEAL_PLANS_SUCCESS = "CREATE_MEAL_PLANS_SUCCESS";
export const CREATE_MEAL_PLANS_FAILED = "CREATE_MEAL_PLANS_FAILED";

//User data
export const GET_USER_DATA_INITIALIZE = "GET_USER_DATA_INITIALIZE";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";

//Get Medications
export const GET_MEDICATION_INITIALIZE = "GET_MEDICATION_INITIALIZE";
export const GET_MEDICATION_SUCCESS = "GET_MEDICATION_SUCCESS";
export const GET_MEDICATION_FAILED = "GET_MEDICATION_FAILED";

//Get Allergies
export const GET_ALLERGIES_INITIALIZE = "GET_ALLERGIES_INITIALIZE";
export const GET_ALLERGIES_SUCCESS = "GET_ALLERGIES_SUCCESS";
export const GET_ALLERGIES_FAILED = "GET_ALLERGIES_FAILED";

//Get Hospitalization Details
export const GET_HOSPITALIZATION_INITIALIZE = "GET_HOSPITALIZATION_INITIALIZE";
export const GET_HOSPITALIZATION_SUCCESS = "GET_HOSPITALIZATION_SUCCESS";
export const GET_HOSPITALIZATION_FAILED = "GET_HOSPITALIZATION_FAILED";

//Get Vaccination details
export const GET_VACCINATION_INITIALIZE = "GET_VACCINATION_INITIALIZE";
export const GET_VACCINATION_SUCCESS = "GET_VACCINATION_SUCCESS";
export const GET_VACCINATION_FAILED = "GET_VACCINATION_FAILED";

//Get Surgery Plant details
export const GET_SURGERY_IMPLANTS_INITIALIZE =
    "GET_SURGERY_IMPLANTS_INITIALIZE";
export const GET_SURGERY_IMPLANTS_SUCCESS = "GET_SURGERY_IMPLANTS_SUCCESS";
export const GET_SURGERY_IMPLANTS_FAILED = "GET_SURGERY_IMPLANTS_FAILED";

//Get Social Behaviour
export const GET_SOCIAL_BEHAVIOUR_INITIALIZE =
    "GET_SOCIAL_BEHAVIOUR_INITIALIZE";
export const GET_SOCIAL_BEHAVIOUR_SUCCESS = "GET_SOCIAL_BEHAVIOUR_SUCCESS";
export const GET_SOCIAL_BEHAVIOUR_FAILED = "GET_SOCIAL_BEHAVIOUR_FAILED";

//Get Badge DetailsSharp
export const SET_UPLOADED_PRESCRIPTION_BADGE_COUNT =
    "SET_UPLOADED_PRESCRIPTION_BADGE_COUNT";
